import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "customer",
  mixins: [mixins],
  data() {
    return {
      columns: [{
        label: '昵称',
        prop: 'nickName'
      }, {
        label: '手机号码',
        prop: 'phone'
      }, {
        label: '邮箱',
        prop: 'email'
      }, {
        label: '注册来源',
        prop: 'regSource'
      }, {
        label: '注册时间',
        prop: 'regTime'
      }, {
        label: '实名状态',
        prop: 'authentication',
        customRender: props => {
          if (props.row.authentication === '实名中') {
            return _createVNode(_resolveComponent("el-tag"), null, {
              default: () => [props.row.authentication]
            });
          } else if (props.row.authentication === '实名失败') {
            return _createVNode(_resolveComponent("el-tag"), {
              "type": "danger"
            }, {
              default: () => [props.row.authentication]
            });
          } else if (props.row.authentication === '已实名') {
            return _createVNode(_resolveComponent("el-tag"), {
              "type": "success"
            }, {
              default: () => [props.row.authentication]
            });
          } else {
            return _createVNode(_resolveComponent("el-tag"), {
              "type": "info"
            }, {
              default: () => [props.row.authentication]
            });
          }
        }
      }],
      topButtons: [{
        name: '导出',
        code: 'customer_export',
        type: 'primary',
        click: this.inputInfo,
        permissions: ['customer_export']
      }],
      searchItems: [{
        prop: 'phone',
        label: '联系电话'
      }, {
        prop: 'authentication',
        label: '实名状态',
        type: 'select',
        items: ['未实名', '已实名', '实名中', '实名失败']
      }]
    };
  },
  methods: {
    inputInfo() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.customer.exportInfo(search).then(res => {
        this.blobFile(res, '车主信息.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    }
  }
};