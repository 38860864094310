export const mixins = {
    props: {
        topMenuId: {
            type: String
        },
        topMenuClick: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeTopToMenu(menu) {
            this.$emit('changeTopToMenu', menu);
        },
        defaultDateShortcuts() {
            return [{
                text: '今日',
                value: () => {
                    const end = new Date();
                    const start = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0);
                    return [start, end];
                }
            }, {
                text: '昨日',
                value: () => {
                    const now = new Date();
                    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);
                    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
                    return [start, end];
                }
            }, {
                text: '本月',
                value: () => {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
                    return [start, end];
                }
            }, {
                text: '上月',
                value: () => {
                    const now = new Date();
                    const start = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0);
                    let endDay = new Date(start.getFullYear(), start.getMonth(), 0).getDate();
                    const end = new Date(now.getFullYear(), now.getMonth() - 1, endDay, 23, 59, 59);
                    return [start, end];
                }
            }, {
                text: '最近一周',
                value: () => {
                    const now = new Date();
                    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0);
                    return [start, now];
                }
            }, {
                text: '最近一个月',
                value: () => {
                    const now = new Date();
                    const start = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() - 1, 0, 0, 0);
                    return [start, now];
                }
            }, {
                text: '最近二个月',
                value: () => {
                    const now = new Date();
                    const start = new Date(now.getFullYear(), now.getMonth() - 2, now.getDate() - 1, 0, 0, 0);
                    return [start, now];
                }
            }, {
                text: '最近三个月',
                value: () => {
                    const now = new Date();
                    const start = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate() - 1, 0, 0, 0);
                    return [start, now];
                }
            }];
        }
    }
}

export const feeDesc = function (feeRule) {
    let message = feeRule.feeType;
    if (feeRule.freeDuration > 0) {
        message += `，前${feeRule.freeDuration}分钟内免费(${feeRule.freeFee ? '计入' : '不计入'}费用时长)`
    }
    if (feeRule.feeType === '按次收费') {
        message += `，每次费用${feeRule.timesFee}元。`
    } else {
        if (feeRule.items) {
            if (feeRule.items.length === 1) {
                message += `，每${feeRule.items[0].duration}分钟费用${feeRule.items[0].fee}元。`
            } else {
                let durationTotal = 0;
                feeRule.items.forEach((item, index) => {
                    if (index === 0) {
                        message += `，前${feeRule.items[index].duration}分钟${feeRule.items[index].fee}元`
                    } else if (index === feeRule.items.length - 1) {
                        message += `，${durationTotal}分钟后每${feeRule.items[index].duration}分钟加收${feeRule.items[index].fee}元。`
                    } else {
                        message += `，${durationTotal}分钟后${feeRule.items[index].duration}分钟加收${feeRule.items[index].fee}元`
                    }
                    durationTotal += feeRule.items[index].duration;
                })
            }
        }
    }
    if (feeRule.maxFee > 0) {
        message += `一天不超过${feeRule.maxFee}元。`;
    }
    return message;
}
